import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  ImageContainer,
  DescriptionContainer,
  TitleContainer,
  Amoeba,
  Icon,
  Line,
} from './style'

const StepThree = () => (
  <Container>
    <DescriptionContainer>
      <h2>Etapa 3</h2>
      <TitleContainer>
        <span>Cuidados que você pode ter em casa</span>
        <Icon />
      </TitleContainer>
      <Line>
        <span>&#8226;</span>
        <p>
          Afeto é o nosso primeiro e mais importante estímulo quando bebês, por
          isso converse, brinque, faça cócegas e carinho;
        </p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>
          Busque seguir os tratamentos indicados por especialistas que você
          tenha visitado;
        </p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>
          Aprimore e diversifique os estímulos à medida que a criança está
          crescendo;
        </p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>
          Conviver com outras crianças da mesma idade é importante para
          socialização;
        </p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>Passeios ao ar livre.</p>
      </Line>
    </DescriptionContainer>
    <ImageContainer>
      <StaticImage
        src="../../../assets/images/available-treatments-step3-picture.png"
        alt=""
        placeholder="blurred"
        className="picture"
      />
      <Amoeba />
    </ImageContainer>
  </Container>
)

export default StepThree
