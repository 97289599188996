import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 724px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 780px;
    }
  `}
`

export const Description = styled.p`
  max-width: 689px;
  ${({ theme }) => theme.fonts.largeBold};
  text-align: center;
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-bottom: 55px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 290px; 
      ${theme.fonts.mediumTextBold};
    }
  `}
`

export const Card = styled.div`
  width: 896px;
  height: 237px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.backgroundLight};

  p {
    max-width: 576px;
    ${({ theme }) => theme.fonts.heading3};
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-bottom: 33px;
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.tertiaryLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 299px;

      p {
        max-width: 238px;
        ${theme.fonts.heading4};
      }

      span {
        ${theme.fonts.largeBold};
      }
    }
  `}
`
