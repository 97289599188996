import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/available-treatments-step1-vector1.svg'
import Amoeba2 from 'assets/vectors/available-treatments-step1-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 687px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: fit-content;
      align-items: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 515px;
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  width: 700px;
  height: 100%;

  .picture {
    position: relative;
    margin-top: 208px;
    margin-left: 201px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      height: 500px;
      transform: translateX(-184px);
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 300px;
      width: 300px;

      .picture {
        width: 180px;
        height: 180px;
        margin-top: 94px;
        margin-left: 161px
      }
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      .picture {
        width: 140px;
        height: 140px;
        margin-top: 135px;
        margin-left: 186px;
      }
    }
  `}
`

export const LeftAmoeba = styled(Amoeba1)`
  position: absolute;
  top: 48px;
  left: -124px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 100%;
      left: 26px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      left: 53px;
    }
  `}
`

export const DescriptionContainer = styled.div`
  max-width: 546px;
  height: fit-content;
  margin-top: 150px;

  h2 {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondaryDarkest};
    text-align: end;
    margin-bottom: 32px;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
    text-align: end;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      transform: translate(70px, -223px);
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0px 28px;
      margin-top: 0px;
      transform: translate(0px, -50px);

      h2 {
        ${theme.fonts.heading3};
        margin-bottom: 22px;
      }

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 237px;
        margin-left: auto;
      }
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
    text-align: end;
    margin-bottom: 32px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      span {
        ${theme.fonts.heading4};
        margin-bottom: 22px;
      }
    }
  `}
`

export const Icon = styled(Amoeba2)`
  position: absolute;
  right: -29px;
  top: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: -16px;
    }
  `}
`
