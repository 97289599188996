import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/home-about-fence.svg'
import Amoeba2 from 'assets/vectors/available-treatments-step1-vector2.svg'
import Vector1 from 'assets/vectors/available-treatments-step2-vector1.svg'
import Vector2 from 'assets/vectors/available-treatments-step2-vector2.svg'
import Vector3 from 'assets/vectors/available-treatments-step2-vector3.svg'
import Vector4 from 'assets/vectors/available-treatments-step2-vector4.svg'
import Vector5 from 'assets/vectors/available-treatments-step2-vector5.svg'
import Vector6 from 'assets/vectors/available-treatments-step2-vector6.svg'
import Vector7 from 'assets/vectors/available-treatments-step2-vector7.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundLight};
`

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      position: relative;
    }
  `}
`

export const DescriptionContainer = styled.div`
  max-width: 546px;
  height: fit-content;
  margin-top: 137px;
  margin-right: 150px;

  h2 {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondaryDarkest};
    margin-bottom: 32px;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 66px;

      h2 {
        ${theme.fonts.heading3};
      }

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 237px;
      }
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  display: flex;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-bottom: 32px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      span {
        max-width: 305px;
        ${theme.fonts.heading4};
      }
    }
  `}
`

export const Icon = styled(Amoeba2)`
  position: absolute;
  left: -29px;
  top: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: -16px;
    }
  `}
`

export const ImageContainer = styled.div`
  width: 546px;
  height: 100%;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      position: absolute;
      width: 200px;
      top: -145px;
      right: 0px;
    }
  `}
`

export const Fence = styled(Amoeba1)`
  margin-top: 50px;
  path {
    fill: ${({ theme }) => theme.colors.tertiaryLight};
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 200px;
    }
  `}
`

export const DisciplineContainer = styled.div`
  display: flex;
  width: 1242px;
  height: 180px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 140px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      height: 300px;
      justify-content: center;
      padding: 0px 28px;
      margin-bottom: 70px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      height: 400px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: fit-content;
    }
  `}
`

export const DisciplineItem = styled.div`
  display: flex;
  height: 74px;
  width: 276px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiaryLight};
  border-radius: 8px;

  ${({ theme }) => theme.fonts.heading4};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 20px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 49px;
      width: 300px;
      margin-bottom: 16px;
      margin-right: 0px;

      ${theme.fonts.mediumTextBlack};
    }
  `}
`

export const ItemsContainer = styled.div`
  width: 1242px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .picture {
    width: 410px;
    height: 335px;
    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 188px;
      height: 154px;
      transform: translateX(90px);
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      padding 0px 28px;
      justify-content: center;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      align-items: start;
      padding-left: 28px;
    }
  `}
`

export const ItemTitle = styled.div`
  position: relative;
  display: flex;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-bottom: 40px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      span {
        ${theme.fonts.heading4};
        margin-bottom: 20px;
      }
    }
  `}
`

export const ItemDescription = styled.div`
  width: ${({ bigDescripion }) => (bigDescripion ? '628px' : '316px')};
  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
    white-space: pre-line;
  }

  span {
    ${({ theme }) => theme.fonts.largeBold};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 237px;
      p {
        ${theme.fonts.smallTextSemiBold};
      }
      span {
        ${theme.fonts.smallTextBlack};
      }
    }
  `}
`

export const Item = styled.div`
  /* 137 caracteres */
  /* 438 caracteres */
  width: ${({ bigDescripion }) => (bigDescripion ? '609px' : '381px')};
  margin-bottom: 90px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: fit-content;
      margin-bottom: 40px;
    }
  `}
`

export const Icon1 = styled(Vector1)`
  position: absolute;
  top: -14px;
  left: -29px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 62px;
    }
  `}
`

export const Icon2 = styled(Vector2)`
  position: absolute;
  top: -14px;
  left: -29px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 52px;
    }
  `}
`

export const Icon3 = styled(Vector3)`
  position: absolute;
  top: -14px;
  left: -29px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 52px;
    }
  `}
`

export const Icon4 = styled(Vector4)`
  position: absolute;
  top: -14px;
  left: -29px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 62px;
    }
  `}
`

export const Curiosity = styled.div`
  position: relative;
  width: 1192px;
  height: 617px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 150px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.tertiaryMedium};

  h3 {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
    max-width: 628px;
    text-align: center;
  }

  span {
    ${({ theme }) => theme.fonts.smallTextBlack};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-top: 30px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 600px;
      height: 800px;
      padding-top: 100px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 304px;
      height: 630px;
      padding-top: 70px;

      h3 {
        text-align: center;
        margin-top: 40px;
      }

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 259px;
      }

      span {
        ${theme.fonts.smallTextBlack};
        text-align: center;
        max-width: 250px;
      }
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 270px;
    }
  `}
`

export const BorderTopLeft = styled(Vector5)`
  position: absolute;
  top: -15px;
  left: -15px;
`
export const BorderTopRight = styled(Vector5)`
  position: absolute;
  top: -15px;
  right: -15px;
  transform: rotate(90deg);
`
export const BorderBottomLeft = styled(Vector5)`
  position: absolute;
  left: -15px;
  bottom: -15px;
  transform: rotate(-90deg);
`
export const BorderBottomRight = styled(Vector5)`
  position: absolute;
  bottom: -15px;
  right: -15px;
  transform: rotate(180deg);
`

export const Amoeba3 = styled(Vector6)`
  position: absolute;
  top: 15px;
  left: 50px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      height: 150px;
      left: 30px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 112px;
      height: 98px;
      left: 10px;
    }
  `}
`

export const Amoeba4 = styled(Vector7)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      display: none;
    }
  `}
`
