import React from 'react'

import {
  Container,
  ItemsContainer,
  DescriptionContainer,
  TitleContainer,
  Amoeba,
  Icon,
  Line,
} from './style'

const StepFour = ({ isMobile }) => (
  <Container>
    <DescriptionContainer>
      <h2>Etapa 4</h2>
      <TitleContainer>
        <span>Conviver em comunidade</span>
        <Icon />
      </TitleContainer>
      <p>
        Os Moebitas são crianças inteligentes com atenção aguçada e, ainda que
        não seja possível a expressão facial, tem um universo rico dentro de si.
        Eles precisam conviver e estar integrados à sociedade, família e escola.
      </p>
      {typeof window !== `undefined` && !isMobile && <Amoeba />}
    </DescriptionContainer>
    <ItemsContainer>
      <TitleContainer>
        <span>O Moebita deve:</span>
        <Icon />
      </TitleContainer>
      <Line>
        <span>&#8226;</span>
        <p>Frequentar escolas comuns;</p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>Participar das atividades recreativas;</p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>
          Aprimore e diversifique os estímulos à medida que a criança está
          crescendo;
        </p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>Se sentir parte dos grupos que frequenta;</p>
      </Line>
      <br />
      <Line>
        <span>&#8226;</span>
        <p>
          Construir uma autoestima que o permita encarar as diferenças com
          naturalidade.
        </p>
      </Line>
      {typeof window !== `undefined` && isMobile && <Amoeba />}
    </ItemsContainer>
  </Container>
)

export default StepFour
