import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      treatments: allContentfulTratamentosETerapiasEtapa2 {
        nodes {
          id
          title1: tituloTratamento1
          title2: tituloTratamento2
          title3: tituloTratamento3
          title4: tituloTratamento4
          description1: descricaoTratamento1 {
            text: descricaoTratamento1
          }
          description2: descrioTratamento2 {
            text: descrioTratamento2
          }
          description3: descrioTratamento3 {
            text: descrioTratamento3
          }
          description4: descrioTratamento4 {
            text: descrioTratamento4
          }
        }
      }
    }
  `)
