import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/available-treatments-step3-vector1.svg'
import Amoeba2 from 'assets/vectors/available-treatments-step1-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 841px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: fit-content;
      align-items: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 755px;
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  width: 700px;
  height: 100%;

  .picture {
    position: relative;
    margin-top: 260px;
    margin-left: 201px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      height: 500px;
      transform: translateX(184px);

      .picture {
        width: 300px;
        margin-top: 138px;
      }
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      transform: none;
      height: 300px;
      width: 300px;

      .picture {
        margin-top: 60px;
        margin-left: 109px;
        width: 156px;
      }
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: 48px;
  left: 0px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      height: 100%;
      top: -57px;
      left: 26px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: -20px;
    }
  `}
`

export const DescriptionContainer = styled.div`
  max-width: 546px;
  height: fit-content;
  margin-top: 150px;

  h2 {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondaryDarkest};
    margin-bottom: 32px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0px 28px;
      margin-top: 0px;

      h2 {
        ${theme.fonts.heading3};
        margin-bottom: 22px;
        margin-top: 67px;
      }
    }
  `}
`

export const Line = styled.div`
  display: flex;

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  span {
    margin-right: 10px;
    margin-top: 5px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 237px;
      }
      span {
        margin-top: 0px;
      }
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-bottom: 32px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      span {
        ${theme.fonts.heading4};
        margin-bottom: 22px;
      }
    }
  `}
`

export const Icon = styled(Amoeba2)`
  position: absolute;
  left: -29px;
  top: 0px;
`
