import React from 'react'

import { Container, Description, Card } from './style'

const Proverb = () => (
  <Container>
    <Description>
      Caso existam limitações ou recomendações específicas, é importante manter
      professores e responsáveis informados.
    </Description>
    <Description>
      Limitações vão existir ao longo dessa jornada, mas com todo o apoio da
      família e comunidade é possível superar qualquer desafio.
    </Description>
    <Card>
      <p>“É preciso uma aldeia inteira para educar uma criança”</p>
      <span>Provérbio Africano</span>
    </Card>
  </Container>
)

export default Proverb
