import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/available-treatments-step4-vector1.svg'
import Amoeba2 from 'assets/vectors/available-treatments-step1-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 907px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: fit-content;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 950px;
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  transform: translateX(-64px);

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 333px;
      height: 221px;
      transform: translateX(-44px);
    }
  `}
`

export const DescriptionContainer = styled.div`
  max-width: 577px;
  height: fit-content;

  h2 {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondaryDarkest};
    margin-bottom: 32px;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0px 28px;
      margin-top: 0px;

      h2 {
        ${theme.fonts.heading3};
        margin-bottom: 22px;
      }

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 237px;
      }
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-bottom: 32px;
    z-index: 2;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      span {
        ${theme.fonts.heading4};
        margin-bottom: 22px;
      }
    }
  `}
`

export const Icon = styled(Amoeba2)`
  position: absolute;
  left: -29px;
  top: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: -16px;
    }
  `}
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-bottom: 140px;
  ${({ theme }) => `
   @media (max-width: ${theme.breakPoints.tablet}) {
      margin-left: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0px 28px;
      margin-bottom: 0px;
      margin-top: 60px;
    }
  `}
`

export const Line = styled.div`
  display: flex;
  width: 530px;

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  span {
    margin-right: 10px;
    margin-top: 5px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 237px;
      }
      span {
        margin-top: 0px;
      }
    }
  `}
`
