import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/available-treatments-header-vector1.svg'
import Amoeba2 from 'assets/vectors/available-treatments-header-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 712px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: 1000px;
      justify-content: flex-start;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 600px;
      flex-direction: column;
      justify-content: flex-start;
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  max-width: 566px;
  margin-top: 100px;
  margin-right: 100px;

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.quaternaryMedium};
  }

  h1 {
    position: relative;
  }

  p {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.backgroundLight};
    max-width: 340px;
    margin-top: 30px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 40px;
      margin-right: 0px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 70px;
      margin-bottom: 30px;

      h1 {
        ${theme.fonts.heading3};
        margin-top: 20px;
      }
      p {
        ${theme.fonts.mediumTextExtraBold};
        margin-top: 20px;
      }
    }
  `}
`

export const DescriptionContainer = styled.div`
  position: relative;
  max-width: 464px;
  height: fit-content;
  margin-top: 150px;

  p {
    position: relative;
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 40px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 0px;

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 304px;
      }
    }
  `}
`

export const LeftAmoeba = styled(Amoeba1)`
  position: absolute;
  top: 48px;
  left: -124px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 121px;
      width: 157px;
      top: 39px;
      left: -59px;
    }
  `}
`
export const RightAmoeba = styled(Amoeba2)`
  position: absolute;
  right: -162px;
  bottom: -133px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`
