import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  ImageContainer,
  DescriptionContainer,
  TitleContainer,
  LeftAmoeba,
  Icon,
} from './style'

const StepOne = () => (
  <Container>
    <ImageContainer>
      <StaticImage
        src="../../../assets/images/home-about-picture.png"
        alt=""
        placeholder="blurred"
        className="picture"
      />
      <LeftAmoeba />
    </ImageContainer>
    <DescriptionContainer>
      <h2>Etapa 1</h2>
      <TitleContainer>
        <span>Diagnóstico</span>
        <Icon />
      </TitleContainer>
      <p>
        Quando uma pessoa é diagnosticada com Síndrome de Moebius, existem
        diferentes tratamentos que vão colaborar para que o Moebita cresça e
        tenha uma vida com mais qualidade. Os cuidados vão além do contato com
        especialistas, também compreendem a convivência com a comunidade e os
        cuidados da família.
      </p>
    </DescriptionContainer>
  </Container>
)

export default StepOne
