import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import useTreatments from 'graphql/useTreatments'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/AvailableTreatments/Header'
import StepOne from 'components/AvailableTreatments/StepOne'
import StepTwo from 'components/AvailableTreatments/StepTwo'
import StepThree from 'components/AvailableTreatments/StepThree'
import StepFour from 'components/AvailableTreatments/StepFour'
import Proverb from 'components/AvailableTreatments/Proverb'

const AvailableTreatments = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  const data = useTreatments().treatments.nodes[0]

  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.secondaryDarkest }}>
        <Seo
          title="Tratamentos e terapias disponíveis"
          description="Quando uma pessoa é diagnosticada com Síndrome de Moebius, existem diferentes tratamentos que vão colaborar para que o Moebita cresça e tenha uma vida com mais qualidade. Os cuidados vão além do contato com especialistas, também compreendem a convivência com a comunidade e os cuidados da família."
        />
        <Header />
        <StepOne />
        <StepTwo data={data} />
        <StepThree />
        <StepFour isMobile={isMobile} />
        <Proverb />
      </Layout>
    </ThemeProvider>
  )
}

export default AvailableTreatments
