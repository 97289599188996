import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  TopContainer,
  DescriptionContainer,
  ImageContainer,
  TitleContainer,
  Fence,
  Icon,
  DisciplineContainer,
  DisciplineItem,
  ItemsContainer,
  ItemTitle,
  ItemDescription,
  Item,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Curiosity,
  BorderTopLeft,
  BorderTopRight,
  BorderBottomLeft,
  BorderBottomRight,
  Amoeba3,
  Amoeba4,
} from './style'

const StepTwo = ({ data }) => (
  <Container>
    <TopContainer>
      <DescriptionContainer>
        <h2>Etapa 2</h2>
        <TitleContainer>
          <span>Quais são os tratamentos e terapias disponíveis?</span>
          <Icon />
        </TitleContainer>
        <p>
          Nos primeiros anos de um Moebita é importante iniciar uma série de
          terapias que vão minimizar as limitações relacionadas à síndrome.
          <br />
          <br />
          Tratamento precisa ser multidisciplinar
        </p>
      </DescriptionContainer>
      <ImageContainer>
        <Fence />
      </ImageContainer>
    </TopContainer>
    <DisciplineContainer>
      <DisciplineItem>Neurológico</DisciplineItem>
      <DisciplineItem>Genético</DisciplineItem>
      <DisciplineItem>Oftalmológico</DisciplineItem>
      <DisciplineItem>Odontológico</DisciplineItem>
      <DisciplineItem>Fonoterápico</DisciplineItem>
      <DisciplineItem>Ortopédico</DisciplineItem>
      <DisciplineItem>Psicológico</DisciplineItem>
      <DisciplineItem>Fisioterápico</DisciplineItem>
    </DisciplineContainer>
    <ItemsContainer>
      <Item>
        <ItemTitle>
          <Icon1 />
          <span>{data.title1}</span>
        </ItemTitle>
        <ItemDescription>
          <p>{data.description1.text}</p>
        </ItemDescription>
      </Item>
      <Item>
        <ItemTitle>
          <Icon2 />
          <span>{data.title2}</span>
        </ItemTitle>
        <ItemDescription>
          <p>{data.description2.text}</p>
        </ItemDescription>
      </Item>
      <Item>
        <ItemTitle>
          <Icon3 />
          <span>{data.title3}</span>
        </ItemTitle>
        <ItemDescription>
          <p>{data.description3.text}</p>
        </ItemDescription>
      </Item>
      <Item bigDescripion>
        <ItemTitle>
          <Icon4 />
          <span>{data.title4}</span>
        </ItemTitle>
        <ItemDescription bigDescripion>
          <p>{data.description4.text}</p>
        </ItemDescription>
      </Item>
      <StaticImage
        src="../../../assets/images/available-treatments-step2-smile.png"
        alt=""
        placeholder="blurred"
        className="picture"
      />
    </ItemsContainer>
    <Curiosity>
      <BorderTopLeft />
      <BorderTopRight />
      <BorderBottomLeft />
      <BorderBottomRight />
      <Amoeba3 />
      <Amoeba4 />
      <h3>Curiosidade:</h3>
      <p>
        1995 - 1ª cirurgia do sorriso foi realizada na menina Chelsey Thomas
        (USA), aos 7 anos. O procedimento foi realizado pelo médico canadense
        Ronald Zuker.
      </p>
      <h3>Tratamentos Alternativos*</h3>
      <p>
        Aplicações de laser;
        <br />
        Aplicação de microcorrentes;
        <br />
        Tratamento com pincel;
        <br />
        Equoterapia.
      </p>
      <span>
        * Os tratamentos precisam ser utilizados a partir de recomendação
        médica.
      </span>
    </Curiosity>
  </Container>
)

export default StepTwo
