import React from 'react'

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  LeftAmoeba,
  RightAmoeba,
} from './style'

const Header = () => (
  <Container>
    <TitleContainer>
      <LeftAmoeba />
      <span>Entendendo Melhor</span>
      <h1>Tratamentos e terapias disponíveis</h1>
      <p>Um caminho para mais qualidade de vida</p>
    </TitleContainer>
    <DescriptionContainer>
      <RightAmoeba />
      <p>
        Quando uma pessoa é diagnosticada com Síndrome de Moebius, existem
        diferentes tratamentos que vão colaborar para que o Moebita cresça e
        tenha uma vida com mais qualidade. Os cuidados vão além do contato com
        especialistas, também compreendem a convivência com a comunidade e os
        cuidados da família.
      </p>
    </DescriptionContainer>
  </Container>
)

export default Header
